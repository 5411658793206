.item-card {
    display: flex;
    flex-direction: column;
    position: relative;
    width: calc(33.333% - 40px);
    margin: 20px;

    .card-image-container {
        
        .card-image {
            max-width: 100%;
            width: 100%;
        }
    }
    
    .card-name-container {
        position: absolute;
        bottom: 3px;
        width: 100%;
        text-align: center;
        visibility: hidden;
        background: mat-color($dark, default);
        padding-top: 10px;
        padding-bottom: 10px;
        opacity: 85%;

        .card-name {
            font-size: 18px;
            font-family: Ubuntu;
            font-variant: normal;
            font-weight: 400;
            color: mat-color($accent, default);
        }
    }
}

@media only screen and (max-width: 600px) {
    .item-card {
        width: calc(100% - 40px) !important;
    }
}