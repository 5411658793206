.mat-table {
    background: mat-color($dark-palette, 500);
    color: mat-color($primary, 500);

    th, td {
        color: mat-color($primary, 500);
        border-color: mat-color($primary, 500);
    }
}

table{
    margin-right: 20px;
    margin-bottom: 20px;
    width: 100%;

    th {
        font-weight: 500;
    }
    td {
        font-weight: 300;
    }
}