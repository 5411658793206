.item-showcase-name {
    font-size: 24px;
    padding-bottom: 20px;
    color: mat-color($accent, default);
}

.item-showcase-description {
    font-style: italic;
    font-size: 18px;
    font-weight: 300;
    color: mat-color($primary, default);
}

.item-showcase-header {
    font-size: 18px;
}