.info-bubble {
    position: absolute;
    right: 20px;
    top: 20px;
    visibility: hidden;
    background-color: mat-color($dark, default);
    border-radius: 50%;
    height: 30px;
    width: 30px;
    padding: 3px;
    opacity: 75%;

    .iconify {
        font-size: 30px;
        color: mat-color($primary, default);    
    }
}