.mat-toolbar {
    height: 50px;
    color: mat-color($accent, 500);
    background: mat-color($dark, 700);
    -webkit-box-shadow: 0px -1px 14px 5px rgba(0,0,0,0.9); 
    box-shadow: 0px -1px 14px 5px rgba(0,0,0,0.9);
}

.drawnbro-logo-container {
    background: mat-color($accent, default);
}

.drawnbro:hover {
    .drawnbro-logo-container {
        background: mat-color($accent2, default) !important;
    }
    span {
        color: mat-color($accent2, default) !important;
    }
}


.drawnbro {
    cursor: pointer;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */

    text-align: center;
    display: flex;
    align-items: center;

    .drawnbro-logo-container {
        height: 36px;
        width: 36px;
        border-radius: 5px;
        margin-right: 10px;

        .drawnbro-logo {
            width: 24px;
            height: 24px;
            margin-top: 6px;
        }
    }

    span {
        margin-top: 2px;
        font-size: 20px;
        font-weight: 500;
        color: mat-color($accent, 500);
        margin-bottom: 3px;
    }
    
}

.social-media-icons {
    bottom: 25px;
    display: flex;
    flex-direction: row;
}

.social-media-icons > div {
    padding: 10px;
    margin: 5px;

    .iconify {
        position: absolute;
    }
}
